import React from 'react';

const PrintPdfIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="50" height="50" fill="#BFEBFF" rx="25" />
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M19.25 23c-.69 0-1.25.56-1.25 1.25s.56 1.25 1.25 1.25 1.25-.56 1.25-1.25S19.94 23 19.25 23zm15-5H33v-3.75c0-.69-.56-1.25-1.25-1.25h-12.5c-.69 0-1.25.56-1.25 1.25V18h-1.25C14.679 18 13 19.679 13 21.75v7.5c0 2.071 1.679 3.75 3.75 3.75H18v3.75c0 .69.56 1.25 1.25 1.25h12.5c.69 0 1.25-.56 1.25-1.25V33h1.25c2.071 0 3.75-1.679 3.75-3.75v-7.5c0-2.071-1.679-3.75-3.75-3.75zM20.5 15.5h10V18h-10v-2.5zm10 20h-10v-5h10v5zm5-6.25c0 .69-.56 1.25-1.25 1.25H33v-1.25c0-.69-.56-1.25-1.25-1.25h-12.5c-.69 0-1.25.56-1.25 1.25v1.25h-1.25c-.69 0-1.25-.56-1.25-1.25v-7.5c0-.69.56-1.25 1.25-1.25h17.5c.69 0 1.25.56 1.25 1.25v7.5z"
      />
    </g>
  </svg>
);

export default PrintPdfIcon;
