import React from 'react';

const DownloadPdfIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="50" height="50" fill="#28AAFF" rx="25" />
      <g stroke="#FFF" strokeWidth="2">
        <path
          d="M11.25 0L11.25 20M23 10L11.5 21 0 10M0 28L23 28"
          transform="translate(14 9)"
        />
      </g>
    </g>
  </svg>
);

export default DownloadPdfIcon;
