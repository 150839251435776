import React from 'react';
import { Helmet } from 'react-helmet';
import qs from 'qs';
import { navigate, PageProps } from 'gatsby';
import ClientOnly from 'components/ClientOnly/ClientOnly';

import useLinkCreator from 'hooks/useLinkCreator';
import menuLinks from 'settings/menuLinks';
import EducationPdfPreview from 'components/EducationSection/EducationPdfPreview';

type EducationPdfPreviewProps = PageProps<
  {},
  {},
  { prevPath?: string | undefined }
>;

type LocationStateType = EducationPdfPreviewProps['location']['state'];

function useGoToPreviousPageOrToEducation(state: LocationStateType) {
  const createLink = useLinkCreator();

  if (state && state.prevPath && typeof state.prevPath === 'string') {
    return () => navigate(state.prevPath as string);
  }

  return () => navigate(createLink(menuLinks.education));
}

const EducationPdfPreviewTemplate = (props: EducationPdfPreviewProps) => {
  const isBrowser = typeof window !== `undefined`;

  const createLink = useLinkCreator();

  const goToPreviousPageOrToEducation = useGoToPreviousPageOrToEducation(
    props.location.state,
  );

  const queryParams = qs.parse(props.location.search, {
    ignoreQueryPrefix: true,
  });

  const pdfFileUrl = queryParams.file;

  const isValidPdfUrl =
    pdfFileUrl && typeof pdfFileUrl === 'string' && pdfFileUrl.endsWith('.pdf');

  if (!isValidPdfUrl && isBrowser) {
    navigate(createLink(menuLinks.education));
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex" />
      </Helmet>
      <ClientOnly>
        <EducationPdfPreview
          pdfFileUrl={`/${pdfFileUrl}`}
          hidePdfModal={() => goToPreviousPageOrToEducation()}
        />
      </ClientOnly>
    </>
  );
};

export default EducationPdfPreviewTemplate;
