import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import React from 'react';
import styled from 'styled-components';
import { Dialog } from '@reach/dialog';
import { detect } from 'detect-browser';

import { theme } from 'styles/styled';
import useWindowSize from 'hooks/useWindowSize';
import useToggle from 'hooks/useToggle';

import _PrintPdfIcon from 'images/Icons/PrintPdfIcon';
import _DownloadPdfIcon from 'images/Icons/DownloadPdfIcon';
import _ClosePdfModalIcon from 'images/Icons/ClosePdfModalIcon';

const Document = React.lazy(() =>
  import('react-pdf/dist/esm/entry.webpack').then((module) => ({
    default: module.Document,
  })),
);

const Page = React.lazy(() =>
  import('react-pdf/dist/esm/entry.webpack').then((module) => ({
    default: module.Page,
  })),
);

const PdfModal = styled(Dialog)`
  position: relative;
  padding: ${theme.dimensions.spacingBase10}px;
  background: ${theme.palette.primary};
  margin: 0;
  width: 100%;
  overflow: scroll;

  @media ${theme.devices.medium} {
    padding: ${theme.dimensions.spacingBase10 * 4}px 45px;
    height: 100%;
  }
`;

const PdfDocument = styled(Document)`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${theme.palette.light};
`;

const PdfPage = styled(Page)`
  display: flex;
  justify-content: center;
  background: ${theme.palette.light};

  @media (max-width: ${theme.deviceSize.large}px) {
    * {
      height: auto !important;
      max-width: 100%;
    }
  }
`;

const PdfModalIconsWrapper = styled.div`
  width: 100%;
  max-width: 1325px;
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  padding: ${theme.dimensions.spacingBase10 * 2}px
    ${theme.dimensions.spacingBase10 * 7}px
    ${theme.dimensions.spacingBase10 * 4}px 0;

  @media ${theme.devices.large} {
    padding: ${theme.dimensions.spacingBase10 * 7}px
      ${theme.dimensions.spacingBase10 * 7}px
      ${theme.dimensions.spacingBase10 * 9}px 0;
  }
`;

const PrintPdfIcon = styled(_PrintPdfIcon)`
  margin-right: ${theme.dimensions.spacingBase10 * 2}px;
  height: ${theme.dimensions.spacingBase10 * 3}px;
  width: ${theme.dimensions.spacingBase10 * 3}px;

  @media ${theme.devices.large} {
    height: ${theme.dimensions.spacingBase10 * 5}px;
    width: ${theme.dimensions.spacingBase10 * 5}px;
  }

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const DownloadPdfIcon = styled(_DownloadPdfIcon)`
  margin-right: ${theme.dimensions.spacingBase10 * 2}px;
  height: ${theme.dimensions.spacingBase10 * 3}px;
  width: ${theme.dimensions.spacingBase10 * 3}px;

  @media ${theme.devices.large} {
    height: ${theme.dimensions.spacingBase10 * 5}px;
    width: ${theme.dimensions.spacingBase10 * 5}px;
  }

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

const ClosePdfModalIcon = styled(_ClosePdfModalIcon)`
  height: ${theme.dimensions.spacingBase10 * 3}px;
  width: ${theme.dimensions.spacingBase10 * 3}px;

  @media ${theme.devices.large} {
    height: ${theme.dimensions.spacingBase10 * 5}px;
    width: ${theme.dimensions.spacingBase10 * 5}px;
  }

  :hover {
    cursor: pointer;
    opacity: 0.7;
  }
`;

function getPdfPageScale(
  width: ReturnType<typeof useWindowSize>['width'],
): number {
  if (width >= 1300) return 2;
  if (width >= 1000) return 1.5;
  return 1;
}

const EducationPdfPreview: React.FC<{
  pdfFileUrl: string;
  hidePdfModal: ReturnType<typeof useToggle>['setOff'];
}> = ({ pdfFileUrl, hidePdfModal }) => {
  const { width } = useWindowSize();

  const [numberOfPagesInPdf, setNumPages] = React.useState<null | number>(null);
  const pdfPageIndexes = Array.from({ length: numberOfPagesInPdf ?? 0 }).map(
    (_, index) => index,
  );

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setNumPages(numPages);
  }

  function triggerPdfFilePrinting() {
    const printWindow = window.open(
      pdfFileUrl,
      'PRINT',
      'height=400,width=600',
    );

    // This is a workaround for Firefox bug
    // that leads to printing about:blank instead of the PDF
    // https://bugzilla.mozilla.org/show_bug.cgi?id=1251028
    if (detect()?.name === 'firefox') {
      setTimeout(() => {
        printWindow?.print();
      }, 1000);
    } else {
      printWindow?.print();
    }
  }

  const isSSR = typeof window === 'undefined';

  if (isSSR) return null;

  return (
    <React.Suspense fallback={null}>
      <PdfModal onDismiss={hidePdfModal} aria-label="Education PDF modal">
        <PdfDocument
          file={pdfFileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
          loading=""
          options={{
            cMapUrl: 'cmaps/',
            cMapPacked: true,
          }}>
          <PdfModalIconsWrapper>
            <PrintPdfIcon onClick={triggerPdfFilePrinting} />
            <a href={pdfFileUrl} download>
              <DownloadPdfIcon />
            </a>
            <ClosePdfModalIcon onClick={hidePdfModal} />
          </PdfModalIconsWrapper>

          {pdfPageIndexes.map((pageIndex) => (
            <PdfPage
              renderAnnotationLayer
              key={pageIndex}
              pageIndex={pageIndex}
              scale={getPdfPageScale(width)}
              loading=""
            />
          ))}
        </PdfDocument>
      </PdfModal>
    </React.Suspense>
  );
};

export default EducationPdfPreview;
