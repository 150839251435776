import React from 'react';

const ClosePdfModalIcon = (props: JSX.IntrinsicElements['svg']) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
    {...props}>
    <g fill="none" fillRule="evenodd">
      <rect width="50" height="50" fill="#BFEBFF" rx="25" />
      <g stroke="#FFF" strokeLinecap="square" strokeWidth="2">
        <path
          d="M0.39 0.39L17.559 17.559"
          transform="translate(16.667 16.667)"
        />
        <path
          d="M0 0L17.949 17.949"
          transform="translate(16.667 16.667) rotate(-90 8.974 8.974)"
        />
      </g>
    </g>
  </svg>
);

export default ClosePdfModalIcon;
